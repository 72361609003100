<template>
  <div class="user">
    <div class="form_info">
      <el-form ref="form" :model="queryInfo" label-width="80px">
        <div class="form">
          <div class="comble_txt">
            <el-form-item label="所在地区:" prop="">
              <el-select v-model="queryInfo.province" placeholder="请选择省份" @change="citychange">
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in operatingList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <div style="display: inline-block; width: 10px"></div>
              <el-select v-model="queryInfo.city" placeholder="请选择市" @change="districtchange">
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in operatingLists"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <div style="display: inline-block; width: 10px"></div>
              <el-select v-model="queryInfo.district" placeholder="请选择区" @change="search">
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in districtList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label=" "
              label-width="0px"
              style="border-top: 1px dashed #efefef; padding-top: 20px; margin-bottom: 0"
            >
              <div class="input-with">
                <div class="left">
                  <el-button v-if="$hasPerms('expert:add')" type="primary" @click="addexpert">新增专家</el-button>
                 <div v-if="$hasPerms('expert:updown')">
                  <el-button style="margin-left: 10px" @click="updateOnShelf">批量发布</el-button>
                  <el-button style="margin-right: 5px" @click="updateOffShelf">批量下架</el-button>

                 </div>
                  <div class="ckeckbox_text">
                    <el-checkbox
                      :indeterminate="isIndeterminate"
                      v-model="checkAll"
                      @change="handleCheckAllChange"
                      >全选</el-checkbox
                    >
                  </div>
                </div>
                <div>
                  <el-input
                    placeholder="专家名称，专家简介"
                    v-model="queryInfo.keys"
                    class="input-with-select"
                  >
                    <el-button class="seach" slot="append" @click="search()">搜索</el-button>
                  </el-input>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div style="display: flex; align-items: center; padding-top: 20px">
      <div style="padding-right: 50px">
        <span>当前条件下共查出{{ total }}份报告</span>
      </div>
      <div style="display: flex; align-items: center; margin-right: 30px">
        按查看次数
        <div class="main">
          <span
            class="arrUp"
            @click="clickUp1"
            :style="{ 'border-bottom-color': orderinfo.color1 }"
          ></span>
          <span
            class="arrDown"
            @click="clickDown2"
            :style="{ 'border-top-color': orderinfo.color2 }"
          ></span>
        </div>
      </div>
      <div style="display: flex; align-items: center; margin-right: 30px">
        按查看人数
        <div class="main">
          <span
            class="arrUp"
            @click="clickUp3"
            :style="{ 'border-bottom-color': orderinfo.color3 }"
          ></span>
          <span
            class="arrDown"
            @click="clickDown4"
            :style="{ 'border-top-color': orderinfo.color4 }"
          ></span>
        </div>
      </div>
      <div style="display: flex; align-items: center; margin-right: 30px">
        按分享人数
        <div class="main">
          <span
            class="arrUp"
            @click="clickUp5"
            :style="{ 'border-bottom-color': orderinfo.color5 }"
          ></span>
          <span
            class="arrDown"
            @click="clickDown6"
            :style="{ 'border-top-color': orderinfo.color6 }"
          ></span>
        </div>
      </div>
      <div style="display: flex; align-items: center; margin-right: 30px">
        按约聊人数
        <div class="main">
          <span
            class="arrUp"
            @click="clickUp7"
            :style="{ 'border-bottom-color': orderinfo.color7 }"
          ></span>
          <span
            class="arrDown"
            @click="clickDown8"
            :style="{ 'border-top-color': orderinfo.color8 }"
          ></span>
        </div>
      </div>
    </div>
    <div
      class="tab_txt"
      v-loading="loading"
      element-loading-text="加载中..."
      element-loading-spinner="el-icon-loading"
    >
      <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
        <div class="item_checkbox">
          <el-checkbox
            @change="latechange($event, item)"
            v-model="item.checked1"
            label=""
          ></el-checkbox>
        </div>
        <div class="storeCenter_item_top">
          <div class="storeCenter_item_top_left">
            <div class="banner_box">
              <img :src="item.headPortrait" alt="" />
            </div>
            <div class="storeCenter_item_top_left_flex">
              <div style="display: flex; align-items: center">
                <h5
                  style="
                    display: inline-block;
                    margin: 0;
                    font-size: 18px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;

                    -webkit-box-orient: vertical;
                  "
                >
                  {{ item.name }}
                </h5>
              </div>
              <div class="releaseMechanism">
                所在地区：{{ item.province }}
                <span v-if="item.city">-</span>
                {{ item.city }}
              </div>
              <!-- <div class="mark_box">
                <div class="mark_box_flex">
                  <span>本地约见：</span>
                  <img src="../../../assets/image/Vector-1.png" alt="" /><span
                    >{{ item.consultingFee }}元</span
                  >
                </div>
                <div class="mark_box_flex" style="margin-left: 20px">
                  <span>线上会议：</span>
                  <img src="../../../assets/image/Vector-1.png" alt="" /><span
                    >{{ item.consultingFee }}元</span
                  >
                </div>
              </div> -->
            </div>
          </div>
          <div class="storeCenter_item_top_center">
            <div v-if="$hasPerms('expert:chat')" class="count_box" @click="listConsultantsPage(item)">
              <div>{{ item.chatAbout || 0 }}</div>
              <div>约聊</div>
            </div>
            <div v-if="$hasPerms('expert:check')" class="count_box" @click="listUserCheckPage(item)">
              <div>{{ item.checkUserCount || 0 }}</div>
              <div>查看</div>
            </div>
            <div v-if="$hasPerms('expert:share')" class="count_box" @click="listSharingPage(item)">
              <div>{{ item.shareCount || 0 }}</div>
              <div>分享</div>
            </div>
          </div>

          <div class="storeCenter_item_top_right">
            <div class="operation_area">
             <div v-if="$hasPerms('expert:updown')">
              <span
                v-if="item.isShelf == 1 || item.isShelf == null"
                class="release"
                @click="OnShelf(item)"
                >发布</span
              >
              <span v-if="item.isShelf == 0" class="shelf" @click="OffShelf(item)">下架</span>
             </div>
             <div v-if="$hasPerms('expert:edit')">
              <span class="office" v-if="item.specialistChannel==2" @click="editcontent(item)">编辑</span>
             </div>
              <div v-if="$hasPerms('expert:freeze')">
                <span v-if="item.isDelete == 0" class="support" @click="delcompany(item)">冻结</span>
              <span v-if="item.isDelete == 1" class="support" @click="cancelcompany(item)"
                >激活</span
              >
              </div>
            </div>
            <div class="details">操作人：{{ item.userName }}</div>
            <div class="details">最近操作时间：{{ item.updateTime }}</div>
          </div>
        </div>
      </div>
      <div class="new_page" v-if="listData.length">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  listPage,
  updateOffShelf,
  updateOnShelf,
  expertFreeze,
  expertThawing
} from '@/api/specialist'
import { getCityAll } from '@/api/demand'
const defaultQueryInfo = Object.freeze({
  partnerChannelId: '', //合作伙伴编号
  schemaName: '', ////智参中心schema值
  pageNum: 1,
  pageSize: 10,
  keys: null,
  city: '', //城市
  province: '', //省份
  orders: 1 //排序 默认传1 2->查看次数升序;3->查看次数降序;4->查看人数升序;5->查看人数降序;6->分享人数升序;7->分享人数降序8->约聊人数升序;9->约聊人数降序
})
const defaultOrder = Object.freeze({
  color1: '',
  color2: '#C6C6C6',
  color3: '',
  color4: '#C6C6C6',
  color5: '',
  color6: '#C6C6C6',
  color7: '',
  color8: '#C6C6C6',
  color9: '',
  color10: '#C6C6C6',
  color11: '',
  color12: '#C6C6C6'
})
export default {
  name: 'expert',
  data() {
    return {
      // 遮罩层
      loading: true,
      total: 0, //咨询条
      checkAll: false,
      isIndeterminate: false,
      ids: [],
      idsList: [],
      idof: [], //判断是否勾选

      queryInfo: { ...defaultQueryInfo },
      //排序字段
      orderinfo: { ...defaultOrder },
      listData: [],
      parteninfo: JSON.parse(sessionStorage.getItem('typeMode')), //合作伙伴信息
      operatingList: [], //省
      operatingLists: [], //市
      districtList: [] //区
    }
  },
  created() {
    this.search()
    this.incity()
  },
  methods: {
    async search() {
      this.loading = true
      this.queryInfo.partnerChannelId = this.parteninfo.id
      this.queryInfo.schemaName = this.parteninfo.schemaName

      const { data: res } = await listPage(this.queryInfo)
      if (res.resultCode == 200) {
        this.listData = res.data.list
        this.listData.forEach((item) => {
          this.$set(item, 'checked1', false)
        })
        this.total = res.data.total
        this.removeSucceed = 1
        this.loading = false
      }
    },
    latechange(bol, row) {
      const index = this.idof.indexOf(row.foreignKey)
      if (index == -1) {
        this.ids.push({
          foreignKey: row.foreignKey,
          schemaName: this.parteninfo.schemaName
        })
        this.idof.push(row.foreignKey)
        this.idsList.push(row)
      } else {
        this.ids.splice(index, 1)
        this.idof.splice(index, 1)
        this.idsList.splice(index, 1)
      }
      console.log( this.ids);
    },
    //  约聊人数的跳转
    listConsultantsPage(item) {
      this.$router.push({
        path: '/service/expert/consultantsNumber',
        query: { id: item.id }
      })
    },
    //  查看人数的跳转
    listUserCheckPage(item) {
      this.$router.push({
        path: '/service/expert/checkCount',
        query: { id: item.id }
      })
    },
    //  分享人数的跳转
    listSharingPage(item) {
      this.$router.push({
        path: '/service/expert/sharing',
        query: { id: item.id }
      })
    },
    //排序按查看次数
    clickUp1() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '2'
      this.search()
      this.orderinfo.color1 = '#0099ff'
      this.orderinfo.color2 = ''
    },
    clickDown2() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '3'
      this.search()
      this.orderinfo.color1 = ''
      this.orderinfo.color2 = '#0099ff'
    },
    //排序按查看人数
    clickUp3() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '4'
      this.search()
      this.orderinfo.color3 = '#0099ff'
      this.orderinfo.color4 = ''
    },
    clickDown4() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '5'
      this.search()
      this.orderinfo.color3 = ''
      this.orderinfo.color4 = '#0099ff'
    },
    //排序按分享人数
    clickUp5() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '6'
      this.search()

      this.orderinfo.color5 = '#0099ff'
      this.orderinfo.color6 = ''
    },
    clickDown6() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '7'
      this.search()

      this.orderinfo.color5 = ''
      this.orderinfo.color6 = '#0099ff'
    },
    //排序按对接人数
    clickUp7() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '8'
      this.search()

      this.orderinfo.color7 = '#0099ff'
      this.orderinfo.color8 = ''
    },
    clickDown8() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '9'
      this.search()

      this.orderinfo.color7 = ''
      this.orderinfo.color8 = '#0099ff'
    },

    //城市
    async incity() {
      const res = await getCityAll()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    //选择城市
    citychange(item) {
      console.log(item)
      this.operatingList.forEach((o) => {
        if (this.queryInfo.province == o.name) {
          this.queryInfo.city = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
          } else {
            let isnum = item.indexOf('市')
            if (isnum == -1) {
              this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
            } else {
              this.queryInfo.city = item
              this.districtList = this.operatingList.find((el) => el.name == item).cityResults
            }
          }
        }
      })
      this.search()
    },
    //选择区
    districtchange(item) {
      this.operatingLists.forEach((o) => {
        if (this.queryInfo.city == o.name) {
          this.queryInfo.district = ''
          if (item == '全部') {
            this.districtList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.districtList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
      this.search()
    },
    //批量下架
    updateOffShelf() {
      if (this.ids.length > 0) {
        let flag = false
        this.idsList.forEach((el) => {
          if (el.isDelete == 1) {
            flag = true
          }
        })
        if (flag) {
          this.$message.warning('您选择的专家中有已冻结的！')
          return
        }
        const that = this
        this.$alert('是否确认批量下架专家？')
          .then(function () {
            return updateOffShelf(that.ids)
          })
          .then((res) => {
            if (res.data.resultCode == 200) {
              this.search()
              this.ids = []
              this.idof=[]
        this.idsList=[]
              this.isIndeterminate = false
              this.checkAll = false

              if (res.data.data) {
                this.$message.warning(res.data.data)
              } else {
                this.$message.success('下架成功')
              }
            }
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择专家')
      }
    },
    //批量上架
    updateOnShelf() {
      if (this.ids.length > 0) {
        let flag = false
        this.idsList.forEach((el) => {
          if (el.isDelete == 1) {
            flag = true
          }
        })
        if (flag) {
          this.$message.warning('您选择的专家中有已冻结的！')
          return
        }
        const that = this
        this.$alert('是否确认批量发布专家？')
          .then(function () {
            return updateOnShelf(that.ids)
          })
          .then((res) => {
            this.search()
            this.ids = []
            this.idof=[]
        this.idsList=[]
            this.isIndeterminate = false
            this.checkAll = false

            if (res.data.data) {
              this.$message.warning(res.data.data)
            } else {
              this.$message.success('发布成功')
            }
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择专家')
      }
    },
    //冻结资讯
    delcompany(row) {
      const params = {
        foreignKey: row.foreignKey,
        schemaName: this.parteninfo.schemaName
      }

      this.$alert('您是否确认冻结？')
        .then(function () {
          return expertFreeze(params)
        })
        .then(() => {
          this.search()
          this.$message.success('冻结成功')
        })
        .catch(() => {})
    },
    //激活资讯
    cancelcompany(row) {
      const params = {
        foreignKey: row.foreignKey,
        schemaName: this.parteninfo.schemaName
      }

      this.$alert('您是否确认激活？')
        .then(function () {
          return expertThawing(params)
        })
        .then((res) => {
          if (res.data.resultCode == 200) {
            this.search()
            this.$message.success('激活成功')
          } else {
            this.$message.warning(res.data.message)
          }
        })
        .catch(() => {})
    },
    //单个下架
    OffShelf(row) {
      if (row.isDelete == 1) {
        this.$message.warning('该报告已冻结')
        return
      }
      const params = [
        {
          foreignKey: row.foreignKey,
          schemaName: this.parteninfo.schemaName
        }
      ]

      this.$alert('是否确认下架报告？')
        .then(function () {
          return updateOffShelf(params)
        })
        .then((res) => {
          this.search()

          if (res.data.data) {
            this.$message.warning(res.data.data)
          } else {
            this.$message.success('下架成功')
          }
        })
        .catch(() => {})
    },
    //单个上架
    OnShelf(row) {
      if (row.isDelete == 1) {
        this.$message.warning('该报告已冻结')
        return
      }
      const params = [
        {
          foreignKey: row.foreignKey,
          schemaName: this.parteninfo.schemaName
        }
      ]

      this.$alert('是否确认上架报告？')
        .then(function () {
          return updateOnShelf(params)
        })
        .then((res) => {
          this.search()

          if (res.data.data) {
            this.$message.warning(res.data.data)
          } else {
            this.$message.success('上架成功')
          }
        })
        .catch(() => {})
    },

    handleCheckAllChange(val) {
      if (val) {
        this.listData.forEach((item) => {
          this.ids.push({
            foreignKey: item.foreignKey,
            schemaName: this.parteninfo.schemaName
          })
          this.idof.push(item.foreignKey)
          this.idsList.push(item)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.listData.forEach((item) => {
          this.ids = []
          this.idsList = []
          this.idof = []
          this.$set(item, 'checked1', false)
        })
      }
      this.isIndeterminate = val
    },
    //新增专家
    addexpert() {
      this.$router.push({
        name: 'addexpert'
      })
    },
    editcontent(item) {
      this.$router.push({
        name: 'addexpert',
        query: { id: item.id }
      })
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  transform: rotate(45deg) scaleY(0);
  width: 4px;
  transition: transform 0.15s ease-in 50ms;
  transform-origin: center;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
.label_list {
  display: flex;
  align-items: center;
  margin-top: 8px;
  .label {
    cursor: pointer;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    margin-right: 15px;
  }
  .acitve_label {
    padding: 0 10px;

    height: 28px;
    color: #146aff;
    background: #cbdeff;
    border-radius: 2px;
  }
}
.Subtag_list {
  padding: 10px;
  min-height: 77px;
  background: #f5f6f7;
  margin-top: 10px;
  display: flex;
  .label_title {
    width: 70px;
    flex-shrink: 0;
    white-space: nowrap;
    // span {
    //   font-family: 'PingFang SC';
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 12px;
    //   line-height: 17px;
    //   margin-right: 15px;
    //   color: #7c7f8e;
    // }
  }
  .label_box {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    div {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height */
      margin-right: 10px;
      color: #1f212b;
      // margin-bottom: 10px;
      height: 20px;
      padding: 5px 10px;
    }
    white-space: pre-wrap;
    .acitve_label {
      // height: 28px;
      color: #146aff;
      background: #cbdeff;
      border-radius: 2px;
    }
  }
}
.enterprise_dialog {
  .tip {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
  }
  .enterprise_box {
    .enterprise_item {
      margin-top: 10px;
      width: 387px;
      padding: 10px;
      background: #f4f4f4;
      border-radius: 1px;
      display: flex;
      justify-content: space-between;
      .left {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        /* identical to box height */

        color: #151515;
      }
      .right {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        color: #fd5469;
      }
    }
  }
}
.comble_txt {
  span {
    margin-left: 20px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
}
.user {
  .form_info {
    background: #ffffff;
    padding: 20px;
  }

  .tab_txt {
    background: #f6f7fb;

    margin-top: 20px;
    padding: 0px;
    padding-bottom: 20px;
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }
  .main {
    display: flex;
    flex-direction: column;
  }

  .arrUp {
    width: 0;
    height: 0;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid #c6c6c6;
    cursor: pointer;
  }

  .arrDown {
    width: 0;
    height: 0;
    margin-top: 2px;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 8px solid #c6c6c6;
    cursor: pointer;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    .left {
      display: flex;
      align-items: flex-end;
    }
    .ckeckbox_text {
      margin-left: 10px;
      position: relative;
      top: 10px;
      /deep/.el-checkbox__inner {
        width: 18px;
        height: 18px;
      }
      /deep/.el-checkbox__label {
        color: #333;
      }
      /deep/.el-checkbox__inner::after {
        height: 10px;
        left: 6px;
      }
    }

    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

  .storeCenter_item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    .item_checkbox {
      line-height: 36px;
      margin: 0px 10px;
    }
    .Relevancy {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #ff7d18;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .Relevancyblue {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #146aff;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .storeCenter_item_top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-bottom: 14px;
      position: relative;
      background: #ffffff;
      padding: 16px 20px 10px 10px;
      .storeCenter_item_top_left {
        display: flex;
        flex-direction: row;
        height: 107.73px;
        width: 450px;
        .banner_box {
          position: relative;
          width: 103.55px;
          height: 103.73px;
          padding-right: 20px;
          img {
            width: 103.55px;
            height: 103.73px;
            border-radius: 4px;
            margin-right: 14px;
          }
          .activity_state {
            position: absolute;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 34px;
            text-align: center;
            color: #ffffff;
            top: -10px;
            left: -9px;
            width: 78px;
            height: 34px;
            border-radius: 5px 15px 15px 0;
          }
          .not_started {
            background: #4e93fb;
          }
          .have_in_hand {
            background: #ff7d18;
          }
          .closed {
            background: #999999;
          }
        }

        .storeCenter_item_top_left_flex {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .generalVersion {
            background: #448aff;
            border-radius: 2px;
            padding: 5px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            /* identical to box height */
            margin: 0 10px;
            color: #ffffff;
          }
          .mark_box {
            display: flex;
            align-items: center;
            .mark_box_flex {
              display: flex;
              align-items: center;
            }
            img {
              width: 14px;
              // height: 14px;
              margin-right: 6px;
              color: #6e6e6e;
            }
          }
          .img_commer {
            display: flex;
            border: 1px solid #146aff;
            border-radius: 39px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #146aff;
            }
            .color_img {
              background: #146aff;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }
          .img_commers {
            display: flex;
            border: 1px solid #ff9c00;
            border-radius: 39px;
            margin: 0px 10px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #ff9c00;
            }
            .color_img {
              background: #ff9c00;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }

          h4 {
            color: #1f2d3d;
            font-size: 26px;
            font-weight: 500;
            margin: 0;
          }

          .address {
            font-size: 14px;
            span {
              padding: 0px 10px;
            }
          }
        }
      }
      .storeCenter_item_top_center {
        display: flex;
        width: 30%;
        .count_box {
          height: 80px;
          width: 120px;
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          cursor: pointer;
          // &:nth-of-type(1) {
          //   border-right: 1px solid #e8e8e8;
          // }
          & > div:nth-of-type(1) {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;

            color: #078bff;
          }
          & > div:nth-of-type(2) {
            margin-top: 20px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
          }
        }
      }

      .storeCenter_item_top_right {
        align-items: stretch;
        height: 100%;
        position: relative;
        .operation_area {
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-width: 200px;

          .buttom_right {
            height: 36px;
            line-height: 1px;
          }
          .release {
            display: inline-block;
            width: 89px;
            height: 36px;
            background: #4e93fb;
            border: 1px solid #4e93fb;
            border-radius: 2px;
            color: #ffffff;
            cursor: pointer;
            font-size: 14px;
            text-align: center;
            line-height: 36px;
          }
          .shelf {
            display: inline-block;
            width: 89px;
            height: 36px;
            font-size: 14px;
            text-align: center;
            line-height: 36px;
            background: #ff7d18;
            /* chengs */

            border: 1px solid #ff7d18;
            border-radius: 2px;
            cursor: pointer;
            color: #ffffff;
          }
        }
        .details {
          text-align: right;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          color: #999999;
          margin-top: 5px;
        }
      }
      .office {
        color: #4e93fb;

        cursor: pointer;
      }

      .support {
        cursor: pointer;
        color: #fd523f;
      }
    }
  }
  /deep/ .el-radio-button__inner {
    border: none;
    margin-right: 12px;
    border-radius: 4px;
  }
}
</style>
